// @flow
import React, { PureComponent } from 'react';
import autosize from 'autosize';

import './index.scss';

export default class TextArea extends PureComponent {
  componentDidMount() {
    autosize(this.textarea);
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value, this.props.name);
  };

  render() {
    return (
      <textarea
        rows={1}
        required
        name={this.props.name}
        value={this.props.value}
        onChange={this.handleChange}
        ref={c => (this.textarea = c)}
        placeholder={this.props.placeholder}
      />
    );
  }
}
