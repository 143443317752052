import { PureComponent } from 'react';
import icons from './service';

export class Icon extends PureComponent {
  static defaultProps = {
    name: '',
    width: 10,
    height: 10,
    className: '',
    color: '#1C1E29',
    onClick: () => {
    },
  };

  render() {
    return (
      icons.getIcon(this.props)
    );
  }
}
