import React from 'react';

import {
  Text,
} from 'components';

const StandardSlot = ({ text }) => (
  <div className="flex-box about-standard-slot">
    <Text
      secondary
      className="about-standard-slot-title"
    >
      {text}
    </Text>
  </div>
);

export default StandardSlot;
