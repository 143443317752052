import React, { PureComponent } from 'react';

import {
  Text,
  PageSlider,
} from 'components';

import Form from './Form';
import ArrowLink from './ArrowLink';

import { AppContext } from 'contexts';

import {
  lineMovementProperties,
} from './constants.js';

import './index.scss';

export default class Contacts extends PureComponent {
  static contextType = AppContext;

  render() {
    return (
      <PageSlider
        pageName="Contacts"
        className="contacts"
        lineMovementProperties={lineMovementProperties}
        slides={[
          {
            id: 0,
            className: 'no-wrap',
            initialState: 'fadeIn',
            fadeInDelay: this.context.lineState === 'loading' ? 4500 : 1500,
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  Contacts
                </Text>

                <Text
                  secondary
                  className="secondary-text"
                >
                  Scalartis teams are located in Seoul (South Korea) and Moscow (Russia).
                </Text>
              </React.Fragment>
            ),
          },
          {
            id: 1,
            initialTop: '100%',
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  +8210–6389–5092
                </Text>

                <div className="flex-box no-grow no-wrap horizontal secondary-text-wrapper">
                  <ArrowLink
                    text="info@scalartis.com"
                    className="a-end no-grow mail-wrapper"
                    linkProps={{
                      href: 'mailto:info@scalartis.com'
                    }}
                  />

                  <div className="flex-box j-end no-grow no-wrap map-wrapper">
                    <Text
                      secondary
                      className="secondary-text"
                    >
                      Seoul, Gangnam-daero 51, Road 10, 107-24
                    </Text>

                    <ArrowLink
                      text="View on Google Map"
                      className="no-grow map-link"
                      linkProps={{
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        href: 'https://goo.gl/maps/PVByuynedvw'
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            ),
          },
          {
            id: 2,
            initialTop: '100%',
            headerColor: '#E7EAF3',
            children: (
              <div className="flex-box horizontal">
                <div className="flex-box no-grow title-wrapper">
                  <Text
                    main
                    className="main-text"
                  >
                    Start a Project
                  </Text>
                </div>

                <Form />
              </div>
            ),
          },
        ]}
      />
    );
  }
}
