export const lineMovementProperties = {
  0: {
    coordinate: 50,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  1: {
    coordinate: 40,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
  },
  2: {
    coordinate: 70,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  3: {
    coordinate: 30,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
  },
  4: {
    coordinate: 80,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  5: {
    coordinate: 40,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
  },
  6: {
    coordinate: 30,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromRightToLeft',
  },
  7: {
    coordinate: 80,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromRightToLeft',
  },
};
