import React, { PureComponent } from 'react';

import {
  Text,
  Slider,
  ArrowText,
  PageSlider,
} from 'components';

import {
  services,
  practices,
  expertise,
  lineMovementProperties,
} from './constants.js';

import './index.scss';

import { AppContext } from 'contexts';

export default class Home extends PureComponent {
  static contextType = AppContext;

  handleHorizontalSliderChange = (current, previous) => {
    this.context.setLineAnimation('wiggle', (current - previous > 0) ? 1 : -1);
  };

  handleReadMoreClick = (data) => {
    this.context.setLineAnimation('routeChange');
    this.context.setCurrentPage(data);
  };

  render() {
    return (
      <PageSlider
        pageName="Home"
        className="home"
        lineMovementProperties={lineMovementProperties}
        slides={[
          {
            id: 0,
            className: 'no-wrap',
            initialState: 'fadeIn',
            fadeInDelay: this.context.lineState === 'loading' ? 4500 : 1500,
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  Excellence deployed
                </Text>

                <Text
                  secondary
                  className="secondary-text"
                >
                  We’re a boutique consultancy providing end-to-end expertise for software development and support.
                </Text>
              </React.Fragment>
            ),
          },
          {
            id: 1,
            initialTop: '100%',
            headerColor: '#E7EAF3',
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  Full-cycle support for software projects of all sizes
                </Text>

                <div className="flex-box no-grow no-wrap secondary-text-wrapper">
                  <Text
                    secondary
                    className="secondary-text"
                  >
                    Tap into our full-cycle expertise on Digital products, Enterprise applications, Big Data and Infrastructure engineering — all with a boutique level of service.
                  </Text>

                  <div className="flex-box no-grow read-more-wrapper">
                    <ArrowText
                      withDelay
                      to="/about"
                      data="About Us"
                      text="Read more"
                      className="read-more"
                      onClick={this.handleReadMoreClick}
                    />
                  </div>
                </div>
              </React.Fragment>
            ),
          },
          {
            id: 2,
            initialTop: '100%',
            children: (
              <Slider
                slides={services}
                sliderName="Services"
                className="slider-comp"
                onChange={this.handleHorizontalSliderChange}
              />
            ),
          },
          {
            id: 3,
            initialTop: '100%',
            children: (
              <Slider
                slides={practices}
                sliderName="Practices"
                className="slider-comp"
                onChange={this.handleHorizontalSliderChange}
              />
            ),
          },
          {
            id: 4,
            initialTop: '100%',
            children: (
              <Slider
                slides={expertise}
                sliderName="Expertise"
                className="slider-comp"
                onChange={this.handleHorizontalSliderChange}
              />
            ),
          },
          {
            id: 5,
            initialTop: '100%',
            className: 'no-wrap',
            headerColor: '#E7EAF3',
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  Excellence is never an accident. It is always the result of high intention, sincere effort, and
                  intelligent execution; it represents the wise choice of many alternatives — choice, not chance, determines your
                  destiny.
                </Text>

                <Text
                  secondary
                  className="secondary-text"
                >
                  Aristotle, 384–322 BC
                </Text>
              </React.Fragment>
            ),
          }
        ]}
      />
    );
  }
}
