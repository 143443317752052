export const lineMovementProperties = {
  0: {
    coordinate: 50,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  1: {
    coordinate: 40,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
  },
  2: {
    coordinate: 70,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  3: {
    coordinate: 30,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
  },
  4: {
    coordinate: 80,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  5: {
    coordinate: 30,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
  },
  6: {
    coordinate: 80,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromRightToLeft',
  },
};

export const services = [
  {
    path: '/services',
    title: 'Imagination',
    content: 'Pinpoint your project’s core objectives and avoid time-consuming pitfalls with our consulting team’s 20+ years of expertise.',
  },
  {
    path: '/services',
    title: 'Design',
    content: 'Quickly move from interactive prototypes to delightful user journeys, with our precisely calibrated development pipeline.',
  },
  {
    path: '/services',
    title: 'Engineering',
    content: 'Our agile delivery teams provide solid software development skills, utilize leading-edge tools and practices, and unlock technology for tomorrow’s business applications.',
  },
  {
    path: '/services',
    title: 'Auditing',
    content: 'Unlock new revenue streams and pinpoint costly leaks, with our targeted analytics and insightful project breakdowns.',
  },
];

export const practices = [
  {
    title: 'Agility',
    path: '/practices',
    content: 'Today’s digital marketplace demands agility — and at Scalartis, agility is in our DNA. We’ve built our business model around a stable operational core, surrounded by teams who can rapidly adapt and rescale over the course of each project. In fact, we see volatility as an opportunity. That’s why we’ve thrived — and can help you thrive too.',
  },
  {
    path: '/practices',
    title: 'Automation',
    content: 'Long-term success is all about scalability — and that requires automation. At Scalartis, we automate everything from our business processes to the infrastructure that drives them. Let us show you how automation can make your job easier and more efficient, too.',
  },
  {
    title: 'Proactivity',
    path: '/practices',
    content: 'We track and benchmark every team’s performance — making sure they’re using your industry’s top tools, following the latest best practices, and bringing all their skills to bear on your project. Plus we proactively seek out and test new approaches, so we can future-proof our business and yours for the years to come.',
  },
];

export const expertise = [
  {
    path: '/expertise',
    title: 'Digital Platforms',
    content: 'More new digital platforms are appearing every year — and it’s tricky to tell which ones your business should be leveraging. That’s why we make it our job to stay on top of this rapidly evolving ecosystem, so we can help you launch products-as-a-service that opens up whole new paths to growth. And if the right platform isn’t out there yet — our team is ready to build it just for you.',
  },
  {
    path: '/expertise',
    title: 'Enterprise Applications',
    content: 'Great software products aren’t just customer-facing — in fact, internal applications are becoming ever more crucial for business success. We’ll show you how to automate your business processes and zero in on your most mission-critical metrics, so you can operate more cohesively and efficiently than ever before — and act on new opportunities before your competitors even realize they exist.',
  },
  {
    path: '/expertise',
    title: 'Internet of Things (IoT)',
    content: 'Today’s Internet is everywhere — not just on our computers and smartphones, but also in our homes, in our cars, and in billions of connected devices that form integral parts of our lives. This new Internet of Things represents an unprecedented opportunity for companies that build its software, and leverage those new applications to drive ROI. We’ll show you precisely how to achieve that — then we’ll build those applications for you.',
  },
  {
    path: '/expertise',
    title: 'Analytics & Data',
    content: 'Humanity is generating data at an unprecedented rate and scale — yet the vast majority of this data remains disorganized, uncorrelated, and un-leveraged. We’re experts at gathering,  cleaning up data and extracting meaning from it, helping you understand your customers and competitors with greater clarity than ever before — and transforming your toughest questions into actionable, profitable insights.',
  },
  {
    path: '/expertise',
    title: 'Edge & Cloud Computing',
    content: 'Moving into the cloud represents a crucial first step toward greater security and efficiency — but it’s only one step toward unleashing your technology’s full power. But we’re also experts in edge computing infrastructure, which enables you to eliminate latency by delivering computational power as close as possible to the end-user. And we’ll custom-build those applications for you, tailored around your most urgent needs.',
  },
];
