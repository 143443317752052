import React, { Fragment, PureComponent } from 'react';

import {
  Text,
  PageSlider,
} from 'components';

import {
  slideSectionData,
  lineMovementProperties,
} from './constants.js';

import './index.scss';
import relationships from 'assets/images/relationships.svg';
import coreValues from 'assets/images/core-values.svg';

import { AppContext } from 'contexts';

const icons = {
  relationships,
  coreValues,
};

export default class Career extends PureComponent {
  static contextType = AppContext;

  renderInnerSlide = (slide, index) => {
    return (
      <React.Fragment>
        <div className="flex-box row">
          <div className="offset-1 offset-m-0 margin-top title">
            <Text main>
              {slide.title.split('\n').map((item, key) => {
                return (
                  <Fragment key={key}>
                    {item}<br/>
                  </Fragment>
                );
              })}
            </Text>
          </div>
        </div>
        {
          !!slide.icon && (
            <div className="flex-box image">
              <img src={icons[ slide.icon ]} alt=""/>
            </div>
          )
        }
        {
          !!slide.description && (
            <div className="flex-box row">
              <div className="offset-1 offset-m-0 col-m-8 description col-4">
                <Text main>
                  {slide.description}
                </Text>
              </div>
            </div>
          )
        }
        {
          !!slide.list && (
            <div className="flex-box short-dec-block">
              {
                slide.list.map((item, i) => {
                  return (
                    <div
                      key={`list${i}`}
                      className="flex-box row"
                    >
                      <div className={`block block-${index + 1}-${i + 1} col-2 col-t-3 col-m-8 ${item.position}`}>
                        {
                          !!item.title && (
                            <Text main className="title">
                              {item.title}
                            </Text>
                          )
                        }
                        {
                          !!item.description && (
                            <div>
                              <Text secondary>
                                {item.description.split('\n').map((desc, key) => {
                                  return (
                                    <Fragment key={key}>
                                      {desc}<br/><br/>
                                    </Fragment>
                                  );
                                })}
                              </Text>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
          )
        }
      </React.Fragment>
    );
  };

  render() {
    return (
      <PageSlider
        pageName="Career"
        className="career"
        lineMovementProperties={lineMovementProperties}
        slides={[
          {
            id: 0,
            className: 'no-wrap',
            initialState: 'fadeIn',
            fadeInDelay: this.context.lineState === 'loading' ? 4500 : 1500,
            children: (
              <React.Fragment>
                <Text main className="main-text">Career</Text>
                <Text secondary className="secondary-text">
                  Scalartis is dedicated to being a great company where people enjoy working and collaborating to solve
                  interesting problems.
                </Text>
              </React.Fragment>
            ),
          },
          {
            id: 1,
            scrollable: true,
            initialTop: '100%',
            contentClassName: 'career-content',
            children: [
              {
                slideClassName: '',
                linePosition: 40,
                content: this.renderInnerSlide(slideSectionData[ 0 ], 0),
              },
              {
                slideClassName: '',
                linePosition: 70,
                content: this.renderInnerSlide(slideSectionData[ 1 ], 1),
              },
              {
                linePosition: 40,
                slideClassName: 'pink',
                content: this.renderInnerSlide(slideSectionData[ 2 ], 2),
              }
            ],
          },
        ]}
      />
    );
  }
}
