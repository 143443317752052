import React, { PureComponent } from 'react';
import {
  withGesture,
} from 'react-with-gesture';
import {
  Spring,
  animated,
} from 'react-spring/renderprops';

import {
  Text,
} from 'components';

import {
  HRZ_SLIDER_DURATION,
  easing,
  preventer,
} from 'helpers';

import './index.scss';

class Titles extends PureComponent {
  state = {
    toX: 0,
    fromX: 0,
    block: false,
    direction: null,
    prevCurrentSlide: 0,
  };

  titles = React.createRef();

  componentDidUpdate(prevProps) {
    const {
      down,
      currentSlide,
      direction: [ x ],
      delta: [ deltaX, deltaY ],
    } = this.props;

    if (this.props.currentSlide !== prevProps.currentSlide) {
      let toX = 0;

      Array.from(this.titles.current.children).forEach((item, index) => {
        if (index < this.props.currentSlide) {
          toX += item.offsetWidth;
        }
      });

      this.setState({
        fromX: this.state.toX,
        toX: currentSlide > prevProps ? toX : (-1 * toX),
      });
    }

    if (!down && prevProps.down && x && (deltaX > 10 || deltaX < -10) && Math.abs(deltaY) < Math.abs(deltaX)) {
      const direction = x > 0;

      if (
        (!direction && (currentSlide !== this.props.slides.length - 1))
        || (direction && !!currentSlide)
      ) {
        const addendum = direction ? -1 : 1;

        this.setState({
          block: true,
        });

        this.props.setCurrentSlide(currentSlide + addendum);
      }
    }
  }

  handleSlotClick = (index) => () => {
    if (!this.state.block) {
      this.props.setCurrentSlide(index);
    }
  };

  render() {
    return (
      <Spring
        native
        delay={0}
        to={{ x: this.state.toX }}
        from={{ x: this.state.fromX }}
        config={{
          easing,
          duration: HRZ_SLIDER_DURATION,
        }}
        onRest={() => {
          this.setState({
            block: false,
          });
        }}
      >
        {({ x }) => (
          <animated.div
            draggable
            ref={this.titles}
            onDragEnd={preventer}
            onDragStart={preventer}
            className="flex-box horizontal no-wrap titles"
            style={{
              transform: x.interpolate(val => `translate(${val}px, 0)`)
            }}
          >
            {this.props.slides.map((slide, index) => {
              if (typeof slide.title === 'string') {
                return (
                  <Text
                    main
                    noWrap
                    key={index}
                    onClick={this.handleSlotClick(index)}
                    className={`slot slot-${index}${this.props.currentSlide === index ? ' selected' : ''}`}
                  >
                    {slide.title}
                  </Text>
                );
              }

              return (
                <div
                  key={index}
                  onClick={this.handleSlotClick(index)}
                  className={`slot slot-${index}${this.props.currentSlide === index ? ' selected' : ''}`}
                >
                  {slide.title}
                </div>
              );
            })}
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default withGesture()(Titles);
