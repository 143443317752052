export const lineMovementProperties = {
  0: {
    coordinate: 50,
  },
  1: {
    coordinate: 40,
  },
  2: {
    coordinate: 30,
  },
  3: {
    coordinate: 80,
  },
};
