import React, { PureComponent } from 'react';
import {
  animated,
  Keyframes,
} from 'react-spring/renderprops';

import {
  easing,
} from 'helpers';

import {
  MenuLine,
} from './MenuLine';

import './index.scss';

export class MenuButton extends PureComponent {
  MenuButtonComponent = Keyframes.Spring({
    toggle: async next => {
      const {
        opened,
      } = this.props;

      const to = opened ? 12 : 0;
      const from = opened ? 0 : 12;

      await next({
        delay: 0,
        from: {
          paddingTop: from,
        },
        paddingTop: to,
        config: {
          easing,
          duration: 900,
        }
      });
    }
  });

  render() {
    const {
      opened,
      headerColor,
    } = this.props;

    const {
      MenuButtonComponent,
    } = this;

    return (
      <div className="flex-box no-grow j-center menu-icon-wrapper">
        <MenuButtonComponent
          native
          state="toggle"
        >
          {props => {
            return (
              <div
                onClick={this.props.handleMenuIconClick}
                className={`flex-box j-center lines-wrapper${opened ? ' opened' : ''}`}
              >
                <animated.div
                  style={{ ...props }}
                  className="flex-box no-grow no-shrink j-between menu-icon"
                >
                  <MenuLine
                    opened={opened}
                    headerColor={headerColor}
                  />
                  <MenuLine
                    opened={opened}
                    headerColor={headerColor}
                  />
                </animated.div>
              </div>
            );
          }}
        </MenuButtonComponent>
      </div>
    );
  }
}
