import React, { PureComponent } from 'react';

import {
  Text,
  PageSlider,
} from 'components';

import {
  lineMovementProperties,
} from './constants.js';

import './index.scss';

import { AppContext } from 'contexts';

export default class Service extends PureComponent {
  static contextType = AppContext;

  handleSlideChange = (direction, currentSlide) => {
    const bottomDirection = direction > 0;

    const newCurrentSlide = currentSlide + direction;

    return ({
      direction: bottomDirection,
      currentSlide: newCurrentSlide,
      previousSlide: newCurrentSlide - 1,
      nextSlide: newCurrentSlide === 1 ? null : newCurrentSlide + 1,
    });
  };

  forceCallbackCondition = (slideNumber, state) => {
    return (slideNumber === 1 && slideNumber === state.currentSlide) || (slideNumber === 2 && state.currentSlide === 1 && !state.nextSlide);
  };

  hideSlow = (slideNumber, state) => {
    return (slideNumber === 2 && state.currentSlide === 1 && !state.nextSlide);
  };

  render() {
    return (
      <PageSlider
        pageName="Services"
        className="service"
        hideSlow={this.hideSlow}
        manualSlideChange={this.handleSlideChange}
        lineMovementProperties={lineMovementProperties}
        forceCallbackCondition={this.forceCallbackCondition}
        slides={[
          {
            id: 0,
            className: 'no-wrap',
            initialState: 'fadeIn',
            fadeInDelay: this.context.lineState === 'loading' ? 4500 : 1500,
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  Services
                </Text>

                <Text
                  secondary
                  className="secondary-text"
                >
                  We deliver the good, reduce development cost, and bring the value to market.
                </Text>
              </React.Fragment>
            ),
          },
          {
            id: 1,
            initialTop: '100%',
            className: 'no-wrap',
            children: (actions, state) => {
              const {
                nextSlide,
              } = state;

              return (
                <div className="item-menu">
                  <div className="row">
                    <div
                      onClick={actions.handleSlideIndicatorClick(2)}
                      onMouseEnter={actions.onIndicatorMouseEnter(2)}
                      onMouseLeave={actions.onIndicatorMouseLeave}
                      className={`offset-1 offset-m-0 hover ${nextSlide === 6 ? '' : 'inactive'}`}
                    >
                      I<span>magination</span>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      onClick={actions.handleSlideIndicatorClick(5)}
                      onMouseEnter={actions.onIndicatorMouseEnter(5)}
                      onMouseLeave={actions.onIndicatorMouseLeave}
                      className={`offset-6 offset-m-5  hover ${nextSlide === 6 ? '' : 'inactive'}`}
                    >
                      A<span>uditing</span>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      onClick={actions.handleSlideIndicatorClick(3)}
                      onMouseEnter={actions.onIndicatorMouseEnter(3)}
                      onMouseLeave={actions.onIndicatorMouseLeave}
                      className={`offset-2 hover ${nextSlide === 6 ? '' : 'inactive'}`}
                    >
                      D<span>esign</span>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={`offset-5 offset-m-4 hover ${nextSlide === 6 ? '' : 'inactive'}`}
                      onMouseLeave={actions.onIndicatorMouseLeave}
                      onMouseEnter={actions.onIndicatorMouseEnter(4)}
                      onClick={actions.handleSlideIndicatorClick(4)}
                    >
                      E<span>ngineering</span>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            id: 2,
            initialTop: '100%',
            className: 'no-wrap',
            children: (
              <React.Fragment>
                <div className="row top-offset">
                  <div className="offset-1 offset-m-0 col-m-8 col-2">
                    <Text
                      secondary
                      className="secondary-text"
                    >
                      Pinpoint your project’s core objectives and avoid time-consuming pitfalls with our consulting team’s 20+ years of expertise.
                    </Text>
                  </div>
                </div>
                <div className="item-menu">
                  <div className="row">
                    <div className="offset-1 offset-m-0 active">
                      I<span>magination</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-6 offset-m-5">
                      A<span>uditing</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-2 offset-m-1">
                      D<span>esign</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-5 offset-m-4">
                      E<span>ngineering</span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
          },
          {
            id: 3,
            initialTop: '100%',
            className: 'no-wrap',
            children: (
              <React.Fragment>
                <div className="row top-offset">
                  <div className="offset-1 offset-m-0 col-m-8 col-2">
                    <Text
                      secondary
                      className="secondary-text"
                    >
                      Quickly move from interactive prototypes to delightful user journeys, with our precisely calibrated development pipeline.
                    </Text>
                  </div>
                </div>
                <div className="item-menu">
                  <div className="row disabled">
                    <div className="offset-1 offset-m-0">
                      I<span>magination</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-6 offset-m-5">
                      A<span>uditing</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-2 offset-m-1 active">
                      D<span>esign</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-5 offset-m-4">
                      E<span>ngineering</span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
          },
          {
            id: 4,
            initialTop: '100%',
            className: 'no-wrap',
            children: (
              <React.Fragment>
                <div className="row top-offset">
                  <div className="offset-1 offset-m-0 col-m-8 col-2">
                    <Text
                      secondary
                      className="secondary-text"
                    >
                      Our agile delivery teams provide solid software development skills, utilize leading-edge tools and practices, and unlock technology for tomorrow’s business applications.
                    </Text>
                  </div>
                </div>
                <div className="item-menu">
                  <div className="row disabled">
                    <div className="offset-1 offset-m-0">
                      I<span>magination</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-6 offset-m-5">
                      A<span>uditing</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-2 offset-m-1">
                      D<span>esign</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-5 offset-m-4 active">
                      E<span>ngineering</span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
          },
          {
            id: 5,
            initialTop: '100%',
            className: 'no-wrap',
            children: (
              <React.Fragment>
                <div className="row top-offset">
                  <div className="offset-1 offset-m-0 col-m-8 col-2">
                    <Text
                      secondary
                      className="secondary-text"
                    >
                      Unlock new revenue streams and pinpoint costly leaks, with our targeted analytics and insightful project breakdowns.
                    </Text>
                  </div>
                </div>
                <div className="item-menu">
                  <div className="row disabled">
                    <div className="offset-1 offset-m-0">
                      I<span>magination</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-6 active offset-m-5">
                      A<span>uditing</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-2 offset-m-1">
                      D<span>esign</span>
                    </div>
                  </div>
                  <div className="row disabled">
                    <div className="offset-5 offset-m-4">
                      E<span>ngineering</span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ),
          },
          {
            id: 6,
            initialTop: '100%',
            className: 'no-wrap',
            headerColor: '#E7EAF3',
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  Organizations which design systems ... are constrained to produce designs which are
                  copies of the communication structures of these organizations.
                </Text>

                <Text
                  secondary
                  className="secondary-text"
                >
                  M. Conway
                </Text>
              </React.Fragment>
            ),
          }
        ]}
      />
    );
  }
}
