export const THRESHOLD = 300;
export const BOTTOM_THRESHOLD = 50;

export const lineMovementProperties = {
  0: {
    coordinate: 50,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  1: {
    coordinate: 40,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
    inner: {
      1: {
        coordinate: 40,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      2: {
        coordinate: 70,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      3: {
        coordinate: 35,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
    }
  },
  2: {
    coordinate: 15,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  3: {
    coordinate: 30,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
  },
};

export const slideSectionData = [
  {
    title: 'Agile',
    icon: 'agile',
    description: 'Scalartis was born agile; it’s in our corporate DNA. This foundational mindset has enabled us to build a stable core while also maintaining the ability to adapt and change quickly. Agile is why we have thrived in this extraordinary age of opportunity.',
    list: [
      {
        title: 'Cross functional',
        position: 'offset-1 offset-m-0',
        description: 'Our teams are comprised of people with diverse expertise from different segments of the company. Product owners collaborate to determine priority and vision; teams work with other teams to determine product delivery.'
      },
      {
        title: 'Transparent',
        position: 'offset-5 offset-m-0',
        description: 'In a fast-paced agile environment, team members must have full access to unfiltered information. Our team members can quickly and easily access any information they need, and they readily share information and knowledge with others in the company.'
      },
      {
        title: 'Rapid & Iterative',
        position: 'offset-3 offset-m-0',
        description: 'Our teams work in short, repeated development cycles (typically in one- or two-week “sprints”) to produce a single primary deliverable very rapidly.'
      }
    ]
  },
  {
    title: 'Comprehensive\nAutomation',
    icon: 'automation',
    description: 'We automate everything, from customer experience to business processes and their applications to the infrastructure that drives them. Our automation solution includes self-healing application management that detects and resolves problems automatically and fully automated infrastructure and operations management.',
    list: [
      {
        title: '',
        position: 'offset-5 offset-m-0',
        description: 'By automating everything, we lower costs, minimize the need for human intervention, and greatly enhance the end-user experience.',
      },
    ]
  },
  {
    title: 'DevOps',
    icon: 'devOps',
    description: 'Our agile delivery teams provide solid software development skills, use the best productivity tools and practices, and unlock technology that’s ready for tomorrow.',
    list: [
      {
        title: 'Trust',
        position: 'offset-3 offset-m-0',
        description: 'The collaboration that forms the foundation of DevOps fosters a culture of shared responsibility and trust. It leads to enhanced transparency and faster, better feedback. It also gives team members the confidence to experiment and innovate in their efforts to improve customer products and services.'
      },
      {
        title: 'Time Management',
        position: 'offset-5 offset-m-0',
        description: 'Unplanned work is a factor that all teams must contend with. DevOps seeks to establish processes and priorities that help manage unexpected work and reduce its impact on team productivity and timely completion of planned work.'
      },
      {
        title: 'Speed',
        position: 'offset-1 offset-m-0',
        description: 'The transparency and community inherent to DevOps lead to decreased downtime and faster issue resolution. Ultimately, DevOps enables us to create new features and services more quickly (with a higher level of quality and stability), resolve issues faster, and respond more nimbly to customer needs.'
      },
    ]
  }
];
