import React from 'react';

import {
  Text,
} from 'components';

const Slot00 = () => (
  <div className="flex-box about-slot-0">
    <Text
      main
      className="about-slot-0-title"
    >
      Our Vision
    </Text>
  </div>
);

export default Slot00;
