export const THRESHOLD = 300;

export const lineMovementProperties = {
  0: {
    coordinate: 50,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  1: {
    coordinate: 40,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
    inner: {
      1: {
        coordinate: 40,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      2: {
        coordinate: 70,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      3: {
        coordinate: 55,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      4: {
        coordinate: 40,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
    }
  },
  2: {
    coordinate: 15,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
};

export const slideSectionData = [
  {
    title: 'Relationships\n& Respect',
    icon: 'relationships',
    description: 'Digital platforms are reshaping how businesses and entire industries operate. They are powerful tools that can help reimagine customer experiences and shape new ways of interacting, launch products as a service, and create innovative business models that can open new paths to growth. ',
    list: [
      {
        title: '',
        position: 'offset-5 offset-t-1 offset-m-0',
        description: 'With demanding consumers at the center of today’s value chain, it’s even more important to deliver engaging experiences.\nScalartis finds the best-fit solution, no matter what the business model, to optimize interactions and unleash the value-creating power of your platform ecosystem.'
      },
    ]
  },
  {
    title: 'Growth\n& Engagement',
    description: '',
    list: [
      {
        title: '',
        position: 'offset-5 offset-t-1 offset-m-0',
        description: 'We want everyone to be their most product selves at work, and that means giving each employee an opportunity to grow. We make our project plans clear and empower team members to work on what interests them and what most suits and draws on their skills.'
      },
    ]
  },
  {
    title: 'Core\nValues',
    icon: 'coreValues',
    description: '',
    list: [
      {
        title: 'Less is More',
        position: 'offset-1 offset-m-0',
        description: 'We see beauty in simplicity. We do not strive to impress with unnecessary complexity; we distill a product, component, or feature to what is essential for achieving elegance, symmetry, and superior functionality.'
      },
      {
        title: 'Change is Good',
        position: 'offset-3 offset-m-0',
        description: 'We embrace and encourage change. Change forces us to adapt in ways that drive growth and development and promotes flexibility. It breaks ingrained patterns and enables us to see things from new or different perspectives.'
      },
      {
        title: 'Invite Challenges',
        position: 'offset-5 offset-m-0',
        description: 'We relish challenges; we don’t run from them. When we tackle difficult problems, we gain confidence and knowledge; we open ourselves to new ideas and potentially new hurdles, which may ultimately lead to superior outcomes.'
      },
    ]
  }
];
