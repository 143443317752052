import React, { Fragment, PureComponent } from 'react';

import {
  Text,
  PageSlider,
} from 'components';

import {
  slideSectionData,
  lineMovementProperties,
} from './constants.js';

import './index.scss';

import { AppContext } from 'contexts';

export default class Expertise extends PureComponent {
  static contextType = AppContext;

  renderInnerSlide = (slide) => {
    return (
      <Fragment>
        <div className="title">
          {slide.title.split('\n').map((item, key) => {
            return (
              <Text key={key} main>
                {item}<br/>
              </Text>
            );
          })}
        </div>
        {
          !!slide.description && (
            <div className="flex-box description">
              <Text main>
                {slide.description}
              </Text>
            </div>
          )
        }
        {
          !!slide.list && (
            <div className="flex-box short-dec-block">
              {
                slide.list.map((item) => {
                  return (!!item.description && item.description.split('\n').map((desc, key) => {
                    return (
                      <Text secondary key={key}>
                        {desc}<br/><br/>
                      </Text>
                    );
                  }));
                })
              }
            </div>
          )
        }
      </Fragment>
    );
  };

  render() {
    return (
      <PageSlider
        pageName="Expertise"
        className="expertise"
        lineMovementProperties={lineMovementProperties}
        slides={[
          {
            id: 0,
            className: 'no-wrap',
            initialState: 'fadeIn',
            fadeInDelay: this.context.lineState === 'loading' ? 4500 : 1500,
            children: (
              <React.Fragment>
                <Text main className="main-text">Expertise</Text>
                <Text secondary className="secondary-text">
                  Our teams and professionals have expert-level proficiency across multiple
                  fields and specializations in the following areas of the technology universe
                </Text>
              </React.Fragment>
            ),
          },
          {
            id: 1,
            scrollable: true,
            withParallax: true,
            initialTop: '100%',
            contentClassName: 'expertise-content',
            children: [
              {
                linePosition: 40,
                slideClassName: '',
                parallaxContent: slideSectionData[ 0 ].text,
                content: this.renderInnerSlide(slideSectionData[ 0 ]),
              },
              {
                linePosition: 70,
                slideClassName: 'pink',
                parallaxContent: slideSectionData[ 1 ].text,
                content: this.renderInnerSlide(slideSectionData[ 1 ]),
              },
              {
                linePosition: 40,
                slideClassName: 'yellow',
                parallaxContent: slideSectionData[ 2 ].text,
                content: this.renderInnerSlide(slideSectionData[ 2 ]),
              },
              {
                linePosition: 20,
                slideClassName: '',
                parallaxContent: slideSectionData[ 3 ].text,
                content: this.renderInnerSlide(slideSectionData[ 3 ]),
              },
              {
                linePosition: 80,
                slideClassName: 'pink',
                parallaxContent: slideSectionData[ 4 ].text,
                content: this.renderInnerSlide(slideSectionData[ 4 ]),
              },
              {
                linePosition: 40,
                slideClassName: 'yellow',
                parallaxContent: slideSectionData[ 5 ].text,
                content: this.renderInnerSlide(slideSectionData[ 5 ]),
              }
            ],
          },
        ]}
      />
    );
  }
}
