import React, { Fragment, PureComponent } from 'react';

import {
  Text,
  PageSlider,
} from 'components';

import {
  slideSectionData,
  lineMovementProperties,
} from './constants.js';

import { AppContext } from 'contexts';

import './index.scss';
import agile from 'assets/images/agile.svg';
import automation from 'assets/images/automation.svg';
import devOps from 'assets/images/dev-ops.svg';

const icons = {
  agile,
  devOps,
  automation,
};

export default class Practices extends PureComponent {
  static contextType = AppContext;

  renderInnerSlide = (slide, index) => {
    return (
      <React.Fragment>
        <div className="flex-box row">
          <div className="offset-1 offset-m-0 margin-top title">
            <Text main>
              {slide.title.split('\n').map((item, key) => {
                return (
                  <Fragment key={key}>
                    {item}<br/>
                  </Fragment>
                );
              })}
            </Text>
          </div>
        </div>
        {
          !!slide.icon && (
            <div className="flex-box image">
              <img src={icons[slide.icon]} alt=""/>
            </div>
          )
        }
        {
          !!slide.description && (
            <div className="flex-box row">
              <div className="offset-1 offset-m-0 col-4 col-m-8 description">
                <Text main>
                  {slide.description}
                </Text>
              </div>
            </div>
          )
        }
        {
          !!slide.list && (
            <div className="flex-box short-dec-block">
              {
                slide.list.map((item, i) => {
                  return (
                    <div
                      key={`list${i}`}
                      className="flex-box row"
                    >
                      <div className={`block block-${index + 1}-${i + 1} col-2 col-t-3 col-m-8 ${item.position}`}>
                        {
                          !!item.title && (
                            <Text main className="title">
                              {item.title}
                            </Text>
                          )
                        }
                        {
                          !!item.description && (
                            <div>
                              <Text secondary>
                                {item.description.split('\n').map((desc, key) => {
                                  return (
                                    <Fragment key={key}>
                                      {desc}<br/><br/>
                                    </Fragment>
                                  );
                                })}
                              </Text>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
          )
        }
      </React.Fragment>
    );
  };

  render() {
    return (
      <PageSlider
        pageName="Practices"
        className="practices"
        lineMovementProperties={lineMovementProperties}
        slides={[
          {
            id: 0,
            className: 'no-wrap',
            initialState: 'fadeIn',
            fadeInDelay: this.context.lineState === 'loading' ? 4500 : 1500,
            children: (
              <React.Fragment>
                <Text main className="main-text">Practices</Text>
                <Text secondary className="secondary-text">
                  Our teams are comprised of people with diverse expertise from different segments of the company.
                  Product owners collaborate to determine priority and vision; teams work with other teams to determine product delivery.
                </Text>
              </React.Fragment>
            ),
          },
          {
            id: 1,
            scrollable: true,
            initialTop: '100%',
            contentClassName: 'practices-content',
            children: [
              {
                slideClassName: '',
                linePosition: 40,
                content: this.renderInnerSlide(slideSectionData[ 0 ], 0)
              },
              {
                linePosition: 70,
                slideClassName: 'pink',
                content: this.renderInnerSlide(slideSectionData[ 1 ], 1)
              },
              {
                linePosition: 40,
                slideClassName: 'yellow',
                content: this.renderInnerSlide(slideSectionData[ 2 ], 2)
              }
            ],
          },
          {
            id: 2,
            initialTop: '100%',
            headerColor: '#E7EAF3',
            className: 'no-wrap a-center',
            children: (
              <React.Fragment>
                <Text main className="main-text">
                  It is not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change.
                </Text>
                <Text secondary className="secondary-text">
                  Charles Darwin
                </Text>
              </React.Fragment>
            ),
          }
        ]}
      />
    );
  }
}
