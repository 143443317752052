export const THRESHOLD = 300;
export const BOTTOM_THRESHOLD = 50;

export const lineMovementProperties = {
  0: {
    coordinate: 50,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  1: {
    coordinate: 40,
    positiveDirection: 'moveFromRightToLeft',
    negativeDirection: 'moveFromRightToLeft',
    inner: {
      1: {
        coordinate: 55,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      2: {
        coordinate: 70,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      3: {
        coordinate: 40,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      4: {
        coordinate: 20,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      5: {
        coordinate: 80,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
      6: {
        coordinate: 50,
        positiveDirection: 'moveFromLeftToRight',
        negativeDirection: 'moveFromLeftToRight',
      },
    }
  },
  2: {
    coordinate: 15,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
  3: {
    coordinate: 15,
    positiveDirection: 'moveFromLeftToRight',
    negativeDirection: 'moveFromLeftToRight',
  },
};

export const slideSectionData = [
  {
    title: 'Digital\nPlatforms',
    text: 'Social Platforms / Knowledge Platforms / Marketplaces & Digital Goods / Media Platforms / Crowdsourcing Platforms / Infrastructure Platforms / ',
    description: 'Digital platforms are reshaping how businesses and entire industries operate. They are powerful tools that can help reimagine customer experiences and shape new ways of interacting, launch products as a service, and create innovative business models that can open new paths to growth.',
    list: [
      {
        description: 'With demanding consumers at the center of today’s value chain, it’s even more important to deliver engaging experiences.\nScalartis finds the best-fit solution, no matter what the business model, to optimize interactions and unleash the value-creating power of your platform ecosystem.'
      }
    ]
  },
  {
    title: 'Enterprise\nApplications',
    text: 'Messaging & Collaboration Systems / Robotic Process Automation / Customer Relationship Management / Billing Systems / Payment Processing / Marketing Systems / Customer  Support / Systems / Security / ',
    description: 'Enterprise Applications are software solutions that integrate all aspects of a company\'s operations and processes enabling it to run cohesively with enhanced productivity and efficiency. Enterprise Application development is highly complicated and has traditionally involved significant time and expense.',
    list: [
      {
        description: 'We apply agile and collaborative methods to accelerate development, while simultaneously creating systems that perfectly incorporate the end user’s needs.\nWe build Enterprise Application systems that make operations easier, safer, quicker, and more streamlined and that can be readily adapted to meet changing needs.'
      },
    ]
  },
  {
    title: 'Internet\nof Things',
    text: 'Embedded Software / Device Management / Platforms / Data Services / Security / ',
    description: 'The Internet of Things (IoT) encompasses the billions of intelligent objects—devices, gadgets, sensors, and systems—all over the globe that communicate with each other and with people through the Internet to assess, measure, and control our environment and our world.',
    list: [
      {
        description: 'IoT gives all of us the opportunity to be more efficient in how we do things; Scalartis helps organizations rethink how they deliver services and produce goods and transform vision into reality.'
      },
    ]
  },
  {
    title: 'Analytics\n& Data',
    text: 'Big Data Analytics /  Predictive Analysis & Forecasting / Anomaly Detection / Real Time Data Processing / Machine Learning /',
    description: 'Scalartis provides analytics systems that help organizations extract meaning from raw data, no matter what their organizational requirements.',
    list: [
      {
        description: 'From uncovering the unexpected, improving predictions, and supporting decision making, to identifying patterns and making decisions with minimal human intervention and receiving nearly instantaneous results from input data, we help our customers gain the deep insights they need to work more efficiently, identify opportunities, and gain a competitive advantage.'
      },
    ]
  },
  {
    title: 'Edge & Cloud\nComputing',
    text: 'Architectures & Pipelines / Public Clouds / Private Clouds / Hybrid Clouds / Container Orchestration / Custom Integration & Automation / ',
    description: 'Scalartis enables business transformation, innovation, and growth by applying today’s most relevant cloud technologies.',
    list: [
      {
        description: 'We offer a full spectrum of technologies and deep cross-industry expertise to help you plan and strategize your approach and to develop systems that are custom-built for your organization’s needs.\nWe’ll accelerate your time to market and give you the tools to stay ahead of the competition.'
      },
    ]
  },
  {
    title: 'Serverless',
    text: 'Serverless architectures & Pipelines / Resources Optimization & Offload / Public Infrastructures / Private Infrastructures /',
    description: 'Serverless is a paradigm in which third-party providers act as the server—developers write business logic functions and cloud providers (who own or provision the servers or virtual machines upon which the code will run) execute those functions.',
    list: [
      {
        description: 'Since companies only use what they need, when they need it, serverless environments often cost less and perform better.\nServerless gives companies a new way to reduce IT operational costs and complexity, enhance scalability, decrease development time, and be more responsive to customer demand.'
      },
    ]
  }
];
