import React, { PureComponent } from 'react';

import {
  Text,
} from 'components';

import './index.scss';

export default class ArrowLink extends PureComponent {
  render() {
    const {
      className,
    } = this.props;

    return (
      <div
        className={`flex-box horizontal j-start a-center arrow-text${className ? ` ${className}` : ''}`}
      >
        <a
          {...this.props.linkProps}
        >
          <Text>
            {this.props.text}
          </Text>
          <span
            className="arrow"
            onClick={this.handleClick}
          >
            &#8594;
          </span>
        </a>
      </div>
    );
  }
}
