import React, { PureComponent } from 'react';
import {
  Icon,
  Text,
} from 'components';

export default class FooterBottom extends PureComponent {
  render() {
    return (
      <div className="flex-box horizontal no-grow footer-bottom">
        <div className="flex-box no-grow a-start j-end footer-logo-wrapper">
          <Icon
            height="62px"
            width="100px"
            name="logo_down"
          />
        </div>

        <div className="flex-box footer-copyright">
          <Text
            secondary
          >
            &copy; 2018-2019 Scalartis, Inc.
          </Text>
        </div>
      </div>
    );
  }
}
