import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Line,
  Header,
  FooterContent,
} from './components';

import Slide from './components/PageSlider/Slide';
import { Routes } from 'pages/Routes';
import { AppContext } from 'contexts';

import 'styles/index.scss';
import './App.scss';

class App extends PureComponent {
  onFooterClick = null;
  onFooterScroll = null;
  onFooterTouchMove = null;
  onFooterTouchStart = null;
  state = {
    hideHeader: false,
    blockContent: true,
    newCoordinate: null,
    currentPage: 'Home',
    lineState: 'loading',
    headerColor: '#1C1E29',
    footerAnimationState: {
      forceAnimationCallback: false,
      top100: false,
      top95: false,
      top0: false,
    }
  };

  componentDidUpdate(prevProps) {
    const gtag = window.gtag;
    if (prevProps.location.pathname !== this.props.location.pathname) {

      // google analytics route change handler
      if (this.props.history.action === 'PUSH' && typeof (gtag) === 'function') { // eslint-disable-line no-restricted-globals
        gtag('config', 'UA-136967872-1', {
          page_location: window.location.href,
          page_path: this.props.location.pathname,
        });
      }

      this.setState({
        footerAnimationState: {
          top100: true,
          top95: false,
          top0: false,
        }
      });
    }
  }

  setLineAnimation = (lineState, newCoordinate = null) => {
    this.setState({
      lineState,
      newCoordinate,
    });
  };

  setHeaderColor = (headerColor) => {
    this.setState({
      headerColor,
    });
  };

  handleMenuIconClick = () => {
    if (this.state.lineState === 'expandFull') {
      this.setLineAnimation('collapse');
    } else {
      this.setLineAnimation('expandFull');
    }
  };

  handleFooterClick = () => {
    if (this.onFooterClick) {
      this.onFooterClick();
    }
  };

  setOnSlideClick = (action) => {
    this.onFooterClick = action;
  };

  handleFooterScroll = (event) => {
    if (this.onFooterScroll) {
      this.onFooterScroll(event);
    }
  };

  setOnFooterScroll = (action) => {
    this.onFooterScroll = action;
  };

  setFooterAnimationState = (footerAnimationState) => {
    this.setState({
      footerAnimationState,
    });
  };

  setFooterAnimationCallback = (fn) => {
    this.footerAnimationCallback = fn;
  };

  setCurrentPage = (currentPage) => {
    this.setState({
      currentPage,
    });
  };

  setOnFooterTouchMove = (action) => {
    this.onFooterTouchMove = action;
  };

  handleFooterTouchMove = (event) => {
    if (this.onFooterTouchMove) {
      this.onFooterTouchMove(event);
    }
  };

  setOnFooterTouchStart = (action) => {
    this.onFooterTouchStart = action;
  };

  handleFooterTouchStart = (event) => {
    if (this.onFooterTouchStart) {
      this.onFooterTouchStart(event);
    }
  };

  setHeaderLogoClick = (action) => {
    this.onHeaderLogoClick = action;
  };

  handleHeaderLogoClick = (event) => {
    if (this.onHeaderLogoClick) {
      this.onHeaderLogoClick(event);
    }
  };

  setBlockContent = (blockContent) => {
    this.setState({
      blockContent,
    });
  };

  setHideHeader = (hideHeader) => {
    this.setState({
      hideHeader,
    });
  };

  render() {
    const {
      lineState,
      footerAnimationState,
    } = this.state;

    return (
      <div className="flex-box app-wrapper">
        <AppContext.Provider
          value={{
            ...this.state,
            setCurrentPage: this.setCurrentPage,
            setHeaderColor: this.setHeaderColor,
            setBlockContent: this.setBlockContent,
            setOnSlideClick: this.setOnSlideClick,
            setLineAnimation: this.setLineAnimation,
            setOnFooterScroll: this.setOnFooterScroll,
            setHeaderLogoClick: this.setHeaderLogoClick,
            setOnFooterTouchMove: this.setOnFooterTouchMove,
            setOnFooterTouchStart: this.setOnFooterTouchStart,
            setFooterAnimationState: this.setFooterAnimationState,
            setFooterAnimationCallback: this.setFooterAnimationCallback,
          }}
        >
          {(lineState !== 'loading') && (
            <Header
              lineState={lineState}
              headerColor={this.state.headerColor}
              setCurrentPage={this.setCurrentPage}
              setLineAnimation={this.setLineAnimation}
              onMenuIconClick={this.handleMenuIconClick}
              handleHeaderLogoClick={this.handleHeaderLogoClick}
              hideHeader={this.state.hideHeader || footerAnimationState.top0}
            />
          )}

          <Routes/>

          <Slide
            initialTop="100%"
            className="footer"
            {...footerAnimationState}
            onSlideClick={this.handleFooterClick}
            onSlideWheel={this.handleFooterScroll}
            onTouchEnd={this.handleFooterTouchMove}
            onTouchStart={this.handleFooterTouchStart}
            unblockSlideChange={this.footerAnimationCallback}
          >
            <FooterContent
              currentPage={this.state.currentPage}
              setCurrentPage={this.setCurrentPage}
              setLineAnimation={this.setLineAnimation}
            />
          </Slide>

          <Line
            state={this.state.lineState}
            setHideHeader={this.setHideHeader}
            currentPage={this.state.currentPage}
            setCurrentPage={this.setCurrentPage}
            setBlockContent={this.setBlockContent}
            newCoordinate={this.state.newCoordinate}
            setLineAnimation={this.setLineAnimation}
          />
        </AppContext.Provider>
      </div>
    );
  }
}

export default withRouter(App);
