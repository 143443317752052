// TODO: pages array should be moved to src/pages/Routes.js for routes generation.
export const pages = [
  {
    path: '/',
    label: 'Home',
  },
  {
    path: '/about',
    label: 'About Us',
  },
  {
    path: '/services',
    label: 'Services',
  },
  {
    path: '/practices',
    label: 'Practices',
  },
  {
    path: '/expertise',
    label: 'Expertise',
  },
  {
    path: '/career',
    label: 'Career',
  },
  {
    path: '/contacts',
    label: 'Contacts',
  },
];

export const socials = [
  {
    label: 'Facebook',
    path: 'https://www.facebook.com/',
  },
  {
    label: 'Twitter',
    path: 'https://www.twitter.com/',
  },
  {
    label: 'Instagram',
    path: 'https://www.instagram.com/',
  },
  {
    label: 'Behance',
    path: 'https://www.behance.net/',
  },
];
