import React, { PureComponent } from 'react';

import Titles from './Titles';
import Content from './Content';
import Indicators from './Indicators';

import './index.scss';

export class Slider extends PureComponent {
  state = {
    currentSlide: 0,
    previousSlide: 0,
  };

  setCurrentSlide = (currentSlide) => {
    if (this.props.block || currentSlide === this.state.currentSlide) {
      return false;
    }

    this.setState({
      currentSlide,
      previousSlide: this.state.currentSlide,
    });

    this.props.onChange(currentSlide, this.state.currentSlide);
  };

  render() {
    const {
      slides,
      className,
      indicatorsBottom,
    } = this.props;

    const {
      currentSlide,
      previousSlide,
    } = this.state;

    return (
      <div className={`flex-box no-grow no-wrap slider-wrapper${className ? ` ${className}` : ''}`}>
        {!indicatorsBottom && (
          <Indicators
            slides={slides}
            currentSlide={currentSlide}
            sliderName={this.props.sliderName}
            onIndicatorClick={this.setCurrentSlide}
          />
        )}

        <Titles
          slides={slides}
          currentSlide={currentSlide}
          setCurrentSlide={this.setCurrentSlide}
        />

        {!this.props.noContent && (
          <Content
            slides={slides}
            key={currentSlide}
            currentSlide={currentSlide}
            previousSlide={previousSlide}
            setCurrentSlide={this.setCurrentSlide}
            currentSlideText={slides[ currentSlide ].content}
            previousSlideText={slides[ previousSlide ].content}
          />
        )}

        {indicatorsBottom && (
          <Indicators
            slides={slides}
            currentSlide={currentSlide}
            sliderName={this.props.sliderName}
            onIndicatorClick={this.setCurrentSlide}
          />
        )}
      </div>
    );
  }
}
