import React, { PureComponent } from 'react';
import {
  animated,
  Keyframes,
} from 'react-spring/renderprops';
import Ticker from 'react-ticker';

import {
  easing,
} from 'helpers';

import { AppContext } from 'contexts';

import './index.scss';


/**
 * Normalize a value to [0,1] range
 * @param  {Number} top    minnimum value in range
 * @param  {Number} bottom maximum value in range
 * @param  {Number} value  value in range
 * @return {Number}        normalized value
 */
const normalize = (top, bottom, value) => {
  const range = bottom - top;
  return (value - top) / range;
};

/**
 * Scales value to [0, window.innerHeight]
 * @param  {Number} value value in any range
 * @return {Number}       scaled value
 */
const scaleToHeight = (value) => {
  const displayTop = 0;
  const displayBottom = window.innerHeight;
  const range = displayBottom - displayTop;
  return (value * range) + displayTop;
};

/**
 * Sets parallaxed element position
 * @param {DOMElement} el     Element to set position
 * @param {Number} scrollTop  Current scroll position
 * @param {Number} topMark    Start of current scrollable slide
 * @param {Number} bottomMark End of current scrollable slide
 */
const setPrlxVerticalPosition = (el, scrollTop, topMark, bottomMark) => {
  const normalVerticalPosition = normalize(topMark, bottomMark, scrollTop);
  const scaledVerticalPosition = scaleToHeight(normalVerticalPosition);
  el.style.transform = `translate3d(0, ${scaledVerticalPosition}px, 0)`;
};

const getTop95Configs = (props, state, cb) => {
  if (props.top95 && (props.fast || (props.isNext && !state.flag && state.prevFlag))) {
    cb && cb();

    return ({
      delay: 0,
      duration: 500,
    });
  }

  if (props.top95 && props.direction) {
    return ({
      delay: 1500,
      duration: 1000,
    });
  }

  if (props.top95 && !props.direction) {
    return ({
      delay: 0,
      duration: 1500,
    });
  }
};

export default class Slide extends PureComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    if (props.scrollable) {
      props.children.forEach((innerSlide, index) => {
        this[ `innerSlide${index}` ] = React.createRef();

        if (props.withParallax) {
          this[ `ticker${index}` ] = React.createRef();
        }
      });
    }
  }

  state = {
    delay: 0,
    flag: false,
    duration: 0,
    className: '',
    prevFlag: false,
    overflow: 'hidden',
    innerSlideNumber: 0,
    animationState: this.props.initialState,
  };

  scrollTop = 0;

  content = React.createRef();

  parallax = React.createRef();

  initialAnimationState = this.props.initialState;

  topPosition = this.props.initialTop;

  SlideComponent = Keyframes.Spring({
    fadeIn: async next => {
      await next({
        delay: this.props.fadeInDelay,
        opacity: 1,
        config: {
          easing,
          duration: 1000,
        },
        from: {
          opacity: 0,
        },
      });
    },

    top100: async next => {
      await next({
        top: '100%',
        delay: 0,
        config: {
          easing,
          duration: this.props.hideSlow ? 1500 : 500,
        },
        from: {
          top: this.topPosition,
        },
      }, true);
    },

    top0: async next => {
      await next({
        top: '0%',
        delay: 100,
        config: {
          easing,
          duration: 1000,
        },
        from: {
          top: this.topPosition,
        },
      }, true);
    },

    top95: async next => {
      const config = getTop95Configs(
        this.props,
        this.state,
        () => {
          this.setState({
            prevFlag: false,
          });
        }
      );

      await next({
        top: '95%',
        from: {
          top: this.topPosition,
        },
        delay: config.delay,
        config: {
          easing,
          duration: config.duration,
        },
      }, true);
    },

    top90: async next => {
      await next({
        top: '90%',
        delay: 0,
        config: {
          easing,
          duration: 500,
        },
        from: {
          top: '95%',
        },
      });
    },
  });

  ContentComponent = Keyframes.Spring({
    current: async next => {
      await next({
        y: 0,
        delay: 200,
        config: {
          easing,
          duration: 1300,
        },
        from: {
          y: 200,
        },
      }, true);
    },

    previous: async next => {
      await next({
        y: -100,
        delay: 0,
        config: {
          easing,
          duration: 800,
        },
        from: {
          y: 0,
        },
      }, true);
    },

    default: async next => {
      await next({
        y: 200,
        from: {
          y: 0,
        },
        delay: 1500,
        config: {
          easing,
          duration: 0,
        },
      }, true);
    },
  });

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.flag && !nextProps.top95) {
      return ({
        flag: false,
      });
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.scrollable && this.props.isCurrent) {
      const {
        innerSlideNumber,
      } = this.state;

      if (innerSlideNumber !== prevState.innerSlideNumber) {
        this.context.setLineAnimation('move', this.props.children[ innerSlideNumber ].linePosition);
      }
    }

    if (this.props.scrollable && this.props.isPrevious) {
      this.setState({
        overflow: 'hidden',
      });
    }
  }

  componentDidMount() {
    this.initialAnimationState = '';
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutTop);
  }

  getAnimationState = () => {
    if (this.initialAnimationState) {
      return this.initialAnimationState;
    }

    if (this.state.flag && this.props.isNext) {
      return 'top90';
    }

    if (this.props.top0) {
      return 'top0';
    }

    if (this.props.top95) {
      return 'top95';
    }

    if (this.props.top100) {
      return 'top100';
    }
  };

  handleClick = () => {
    if (this.state.flag) {
      this.props.onSlideClick(1);
    }
  };

  handleSlideWheel = (event) => {
    if (this.props.onSlideWheel) {
      this.props.onSlideWheel(event);
    }
  };

  handleFooterTouchMove = (event) => {
    if (this.props.onTouchEnd) {
      this.props.onTouchEnd(event);
    }
  };

  handleFooterTouchStart = (event) => {
    if (this.props.onTouchStart) {
      this.props.onTouchStart(event);
    }
  };

  handelMouseEnter = () => {
    if (this.props.top95 && this.topPosition && this.topPosition === '95%') {
      this.setState({
        flag: true,
        prevFlag: false,
      });
    }
  };

  handleMouseLeave = () => {
    if (this.state.flag) {
      this.setState({
        flag: false,
        prevFlag: true,
      });
    }
  };

  handleAnimationRest = (data) => {
    this.topPosition = data.top;

    if (!this.props.blockAnimationCallback && ((this.topPosition === '95%' && this.props.top95) || this.props.forceAnimationCallback)) {
      this.props.unblockSlideChange();
    }

    if (this.props.scrollable && this.props.top0 && this.topPosition === '0%') {
      this.content.current.scrollTop = 1;

      if (this.parallax.current) {
        this.parallax.current.container.scrollTop = 1;
      }

      this.setState({
        overflow: 'scroll',
      });
    } else if (this.state.overflow !== 'hidden') {
      this.setState({
        overflow: 'hidden',
      });
    }
  };

  mapScrollableChildren = () => {
    return this.props.children.map((innerSlide, index) => {
      const {
        content,
      } = innerSlide;

      return (
        <div
          key={`slide${index}`}
          ref={this[ `innerSlide${index}` ]}
          className={`inner-slide slide-${index + 1}`}
        >
          {content}
          {this.props.withParallax && (
            <div className="ticker-content" ref={this[ `ticker${index}` ]}>
              <Ticker speed={25}>
                {() => innerSlide.parallaxContent}
              </Ticker>
            </div>
          )}
        </div>
      );
    });
  };

  handleContentScroll = () => {
    const { scrollHeight, scrollTop } = this.content.current;
    const { height } = this.content.current.getBoundingClientRect();

    this.handleScrollableSlideBehaviour(scrollHeight, scrollTop, height);
  };

  handleScrollableSlideBehaviour = (scrollHeight, scrollTop, height) => {
    if (this.props.scrollable && this.topPosition === '0%' && this.props.isCurrent) {
      const {
        children,
      } = this.props;

      const bottomDirection = (scrollTop - this.scrollTop) > 0;

      if (scrollTop === 0) {
        this.timeoutTop = setTimeout(() => {
          this.props.unblockDirectionSlideChange('Top', () => {
            this.props.onSlideClick(-1);
          });

          clearTimeout(this.timeoutTop);
        }, 500);
      }

      children.forEach((slide, index) => {

        const currentSlide = this[ `innerSlide${index}` ].current;
        const nextSlideIndex = (index + 1) === children.length ? index : (index + 1);

        const topMark = currentSlide.offsetTop;
        const trackingLine = scrollTop + window.innerHeight;
        const bottomMark = nextSlideIndex === index
          ? scrollHeight
          : this[ `innerSlide${nextSlideIndex}` ].current.offsetTop;

        if (trackingLine >= topMark && trackingLine < bottomMark) {
          this.setState({
            innerSlideNumber: index,
            className: slide.slideClassName,
          });

          // if there is no ticker - we are not going to call the setPrlxVerticalPosition
          if (this[`ticker${index}`]) {
            setPrlxVerticalPosition(
              this[ `ticker${index}` ].current,
              scrollTop, topMark, bottomMark
            );
          }

        }
      });

      if (
        scrollTop
        && (
          (scrollHeight > (scrollTop + height))
          // || (scrollHeight === (scrollTop + height) && (scrollTop - this.scrollTop) < 0)
        )
      ) {
        this.props.blockSlideChange();
        this.props.setNextSlide(null, true);
      } else if ((scrollTop + height) >= scrollHeight && !this.props.nextSlide) {
        this.block = true;

        this.props.setNextSlide(
          this.props.slideNumber + 1,
          true,
          () => {
            this.timeout = setTimeout(() => {
              this.block = false;
              this.props.unblockDirectionSlideChange('Bottom');

              clearTimeout(this.timeout);
            }, 1500);

          }
        );
      } else if ((scrollTop + height) >= scrollHeight && bottomDirection && !!this.props.nextSlide) {
        if (!this.props.direction && !this.block) {
          this.setState({
            overflow: 'hidden'
          },
          () => {
            if (this.props.isCurrent) {
              this.props.unblockDirectionSlideChange('Bottom', () => {
                this.props.onSlideClick(1);
              });
            }
          });
        }
      }

      if (this.props.isCurrent && scrollTop && this.state.overflow !== 'scroll') {
        this.setState({
          overflow: 'scroll',
        });
      }

      this.scrollTop = scrollTop;
    }
  };

  handleContentAnimationRest = () => {
    if (this.props.scrollable && this.props.isCurrent) {
      this.setState({
        overflow: 'scroll',
      });

      if (!this.props.direction) {
        this.props.unblockDirectionSlideChange('Bottom');
      }
    }
  };

  render() {
    const {
      SlideComponent,
      ContentComponent,
    } = this;

    const {
      scrollable,
    } = this.props;

    return (
      <SlideComponent
        native
        state={this.getAnimationState()}
        onRest={this.handleAnimationRest}
        onFrame={(data) => {
          this.topPosition = data.top;
        }}
        onStart={() => {
          this.setState({
            overflow: 'hidden',
          });
        }}
      >
        {props => {
          return (
            <animated.div
              style={{ ...props }}
              onClick={this.handleClick}
              onWheel={this.handleSlideWheel}
              onMouseEnter={this.handelMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onTouchEnd={this.handleFooterTouchMove}
              onTouchStart={this.handleFooterTouchStart}
              className={`flex-box slide ${this.props.className}${this.state.flag ? ' cursorDown' : ''} ${this.props.slideNumber === 0 ? 'static' : ''} ${this.state.className}`}
            >
              <ContentComponent
                native
                onRest={this.handleContentAnimationRest}
                state={this.props.isCurrent ? 'current' : (this.props.isPrevious ? 'previous' : 'default')}
              >
                {({ y }) => {
                  return (
                    <animated.div
                      ref={this.content}
                      onScroll={this.handleContentScroll}
                      style={{
                        width: '100%',
                        padding: '0 40px',
                        overflowX: 'hidden',
                        display: scrollable ? 'block' : 'flex',
                        transform: y.interpolate(v => `translate(0, ${v}px)`),
                        overflowY: this.props.isCurrent ? this.state.overflow : 'hidden',
                      }}
                      className={`flex-box slide-content ${this.props.contentClassName || ''}`}
                    >
                      {!scrollable ? this.props.children : this.mapScrollableChildren()}
                    </animated.div>
                  );
                }}
              </ContentComponent>
            </animated.div>
          );
        }}
      </SlideComponent>
    );
  }
}
