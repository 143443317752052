import React, { PureComponent } from 'react';

import Input from '../Input';
import TextArea from '../TextArea';

export default class Form extends PureComponent {
  state = {
    name: '',
    message: '',
    mailOrPhone: '',
  };

  handleInputChange = (value, name) => {
    this.setState({ [ name ]: value });
  };

  handleFormSubmit = () => {
    window.emailjs.send('gmail', 'request_mail', this.state)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            name: '',
            message: '',
            mailOrPhone: '',
          });
        }
      });
  };

  render() {
    return (
      <div className="flex-box no-grow no-wrap form-wrapper">
        <div className="flex-box form">
          <Input
            name="name"
            value={this.state.name}
            placeholder="Full Name"
            onChange={this.handleInputChange}
          />
          <Input
            name="mailOrPhone"
            value={this.state.mailOrPhone}
            onChange={this.handleInputChange}
            placeholder="Email address or Telephone"
          />
          <TextArea
            name="message"
            placeholder="Message"
            value={this.state.message}
            onChange={this.handleInputChange}
          />
        </div>

        <div className="flex-box no-grow no-wrap submit-wrapper">
          <div
            onClick={this.handleFormSubmit}
            className="flex-box horizontal no-grow no-wrap submit-text-wrapper"
          >
            <span className="submit-text">
              Send Request
            </span>
            <span className="submit-arrow">
              &#8594;
            </span>
          </div>
        </div>
      </div>
    );
  }
}
