import React from 'react';

import Slot00 from './Slots/Slot00';
import StandardSlot from './Slots/StandardSlot';

export const lineMovementProperties = {
  0: {
    coordinate: 50,
  },
  1: {
    coordinate: 40,
  },
  2: {
    coordinate: 30,
  },
  3: {
    coordinate: 80,
  },
};

export const slider = [
  {
    title: <Slot00 />,
  },
  {
    title: (
      <StandardSlot
        text="In order to succeed in the age of disruption, companies must change their basic approach. They need to shift their emphasis from perpetuating stability to disrupting themselves."
      />
    ),
  },
  {
    title: (
      <StandardSlot
        text="We help clients value learning over success and value the ability to change direction over the ability to maintain course. In other words, we help shift core competency from efficiency to adaptability."
      />
    ),
  },
  {
    title: (
      <StandardSlot
        text="We continuously research new technology and business models through real-world use cases and direct customer communication. We validate suggestions prior to implementation to avoid waste."
      />
    ),
  },
  {
    title: (
      <StandardSlot
        text="We help clients leverage tools and technologies that have proven themselves in many different fields and have been successfully used to create customer value by companies around the world."
      />
    ),
  },
  {
    title: (
      <StandardSlot
        text="Instead of excelling at doing the same things better, faster, and cheaper, they need to challenge themselves to continually do different things and continually do them differently."
      />
    ),
  },
];

