import React, { PureComponent } from 'react';

import {
  Icon,
  ArrowText,
  MenuButton,
} from 'components';

import {
  getClassNames,
} from 'helpers';

import './index.scss';

export class Header extends PureComponent {
  state = {
    opened: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      lineState,
    } = nextProps;

    if ((lineState === 'collapse' || lineState === 'routeChangeFromMenu') && prevState.opened) {
      return ({
        opened: false,
      });
    }

    return null;
  }

  handleMenuIconClick = () => {
    this.setState({
      opened: !this.state.opened,
    });

    this.props.onMenuIconClick();
  };

  handleContactUsClick = (data) => {
    this.props.setLineAnimation('routeChange');
    this.props.setCurrentPage(data);
  };

  render() {
    const {
      headerColor,
    } = this.props;

    return (
      <div className={`flex-box horizontal no-grow no-wrap header ${getClassNames(this.state)} ${this.props.hideHeader ? 'hide' : ''}`}>
        {!this.props.hideHeader && (
          <MenuButton
            key={this.state.opened}
            headerColor={headerColor}
            opened={this.state.opened}
            handleMenuIconClick={this.handleMenuIconClick}
          />
        )}

        {!this.state.opened && (
          <React.Fragment>
            <div className="flex-box no-grow a-start logo-wrapper">
              <Icon
                height="50px"
                width="88.6px"
                name="logo_up"
                color={headerColor}
                onClick={this.props.handleHeaderLogoClick}
              />
            </div>

            <ArrowText
              withDelay
              to="/contacts"
              data="Contacts"
              text="Contact us"
              className="header-right"
              onClick={this.handleContactUsClick}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
