// TODO: pages array should be moved to src/pages/Routes.js for routes generation.
// TODO: This file is duplicated from src/components/FooterContent
export const pages = [
  {
    path: '/',
    label: 'Home',
  },
  {
    path: '/about',
    label: 'About Us',
  },
  {
    path: '/services',
    label: 'Services',
  },
  {
    path: '/practices',
    label: 'Practices',
  },
  {
    path: '/expertise',
    label: 'Expertise',
  },
  {
    path: '/career',
    label: 'Career',
  },
  {
    path: '/contacts',
    label: 'Contacts',
  },
];
