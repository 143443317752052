import React, { PureComponent } from 'react';

import {
  getClassNames,
} from 'helpers';

import './index.scss';

export class Text extends PureComponent {
  render() {
    const {
      main,
      noWrap,
      onClick,
      secondary,
      className,
    } = this.props;

    return (
      <span
        onClick={onClick ? onClick : () => null}
        className={`text${getClassNames({ main, secondary, noWrap })}${className ? ` ${className}` : ''}`}
      >
        {this.props.children}
      </span>
    );
  }
}
