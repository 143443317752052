import React, { PureComponent } from 'react';

import {
  Text,
  Slider,
  PageSlider,
} from 'components';

import {
  slider,
  lineMovementProperties,
} from './constants.js';

import './index.scss';

import { AppContext } from 'contexts';

export default class About extends PureComponent {
  static contextType = AppContext;

  handleHorizontalSliderChange = (current, previous) => {
    this.context.setLineAnimation('wiggle', (current - previous > 0) ? 1 : -1);
  };

  render() {
    return (
      <PageSlider
        className="about"
        pageName="About Us"
        lineMovementProperties={lineMovementProperties}
        slides={[
          {
            id: 0,
            className: 'no-wrap',
            headerColor: '#E7EAF3',
            initialState: 'fadeIn',
            fadeInDelay: this.context.lineState === 'loading' ? 4500 : 1500,
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  About Us
                </Text>

                <Text
                  secondary
                  className="secondary-text"
                >
                  Scalartis is a boutique technology consulting company with high-skilled IT and design professionals
                </Text>

                <Text
                  secondary
                  className="secondary-text"
                >
                  We deliver the good, reduce development cost as much as possible, and bring the value to market as soon as possible.
                </Text>
              </React.Fragment>
            ),
          },
          {
            id: 1,
            initialTop: '100%',
            children: (
              <Slider
                noContent
                slides={slider}
                indicatorsBottom
                className="slider-comp"
                onChange={this.handleHorizontalSliderChange}
              />
            ),
          },
          {
            id: 2,
            initialTop: '100%',
            classname: 'no-wrap',
            headerColor: '#E7EAF3',
            children: (
              <React.Fragment>
                <Text
                  main
                  className="main-text"
                >
                  It is not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change.
                </Text>

                <Text
                  secondary
                  className="secondary-text"
                >
                  Charles Darwin
                </Text>
              </React.Fragment>
            ),
          },
        ]}
      />
    );
  }
}
