import React, { PureComponent } from 'react';
import {
  animated,
  Keyframes,
} from 'react-spring/renderprops';

import {
  Icon,
  ArrowText,
} from 'components';

import {
  easing,
} from 'helpers';

import { AppContext } from 'contexts';

import {
  pages,
} from './constants';

import './index.scss';

const hide = {
  delay: 0,
  opacity: 0,
  config: {
    easing,
    duration: 500,
  },
};

const MenuFooter = Keyframes.Spring({
  hide,
  show: {
    delay: 0,
    bottom: 0,
    opacity: 1,
    from: {
      opacity: 0,
      bottom: -75,
    },
    config: {
      easing,
      duration: 800,
    }
  },
});

const Content = Keyframes.Trail({
  hide,
  show: {
    x: 0,
    opacity: 1,
    from: {
      x: 30,
      opacity: 0,
    },
    config: {
      easing,
      duration: 500,
    }
  },
});

export default class Menu extends PureComponent {
  static contextType = AppContext;

  handleRouteChange = (data) => {
    this.props.setCurrentPage(data);
    this.context.setLineAnimation('routeChangeFromMenu');
  };

  render() {
    const animationState = this.props.isOpened ? 'show' : 'hide';

    return (
      <div className="menu-wrapper">
        <div className="flex-box horizontal no-wrap content-wrapper">
          <div className="flex-box j-between no-wrap navigation-wrapper">
            <Content
              native
              items={pages}
              state={animationState}
              keys={item => item.label}
            >
              {(item) => ({ x, ...props }) => (
                <animated.div
                  style={{
                    transform: x.interpolate(val => `translate3d(${val}%,0,0)`),
                    ...props,
                  }}
                  className="flex-box no-grow a-start item-wrapper"
                >
                  <ArrowText
                    to={item.path}
                    text={item.label}
                    data={item.label}
                    onClick={this.handleRouteChange}
                    className={`no-grow nav-item${this.props.currentPage === item.label ? ' selected' : ''}`}
                  />
                </animated.div>
              )}
            </Content>
          </div>
        </div>

        <MenuFooter
          native
          state={animationState}
        >
          {(style) => (
            <animated.div
              style={{ ...style }}
              className="flex-box a-start j-end menu-footer"
            >
              <Icon
                height="62px"
                width="100px"
                name="logo_down"
                color="#FFFFFF"
              />
            </animated.div>
          )}
        </MenuFooter>
      </div>
    );
  }
}
