import React, { PureComponent } from 'react';
import {
  animated,
  Keyframes,
  config as baseConfig,
} from 'react-spring/renderprops';

import {
  Text,
} from 'components';

import {
  easing,
} from 'helpers';

import Menu from './Menu';

import './index.scss';

export class Line extends PureComponent {
  state = {
    x: 0,
    expanded: false,
    collapseStarted: false,
  };

  nextLinePosition = {
    width: '100%',
    left: '0%',
  };

  lineCollapseOrigin = {
    width: `${200 / window.innerWidth}%`,
    left: '50%'
  };

  LineComponent = Keyframes.Spring({
    loading: async next => {
      const config = {
        easing,
        duration: 200,
        ...baseConfig.slow
      };

      await next({
        config,
        from: {
          x: 0,
          width: '0%',
        },
        x: 20,
        width: '20%',
      });
      await next({
        config,
        from: {
          x: 20,
          width: '20%',
        },
        x: 80,
        width: '80%',
      });
      await next({
        config,
        from: {
          x: 80,
          width: '80%',
        },
        x: 100,
        width: '100%',
      });
    },

    // Moving line between slides Animation
    move: async next => {
      await next({
        delay: 0,
        config: {
          easing,
          duration: 1500,
        },
        from: { left: this.nextLinePosition.left },
        left: `${this.props.newCoordinate}%`,
      });
    },

    collapse: async next => {
      await next({
        delay: 400,
        from: this.nextLinePosition,
        ...this.lineCollapseOrigin,
        config: {
          easing,
          duration: 1000,
        },
      }, true);
    },

    expandFull: async next => {
      await next({
        delay: 0,
        left: '0%',
        width: '100%',
        config: {
          easing,
          duration: 1000,
        },
        from: this.nextLinePosition,
      }, true);
    },

    moveFromRightToLeft: async next => {
      const {
        newCoordinate,
      } = this.props;

      const lineWidth = 200 / window.innerWidth;
      const left = parseInt(this.nextLinePosition.left.slice(0, 2), 10);
      const right = 100 - left - lineWidth;
      const newCoordinateStr = `${newCoordinate}%`;
      const rightStr = `${right}%`;

      const middleWidth = `${Math.abs(100 - newCoordinate - right)}%`;

      await next({
        delay: 0,
        config: {
          easing,
          duration: 500,
        },
        right: rightStr,
        left: newCoordinateStr,
        from: {
          right: rightStr,
          ...this.nextLinePosition
        },
        width: middleWidth,
      });
      await next({
        delay: 500,
        config: {
          easing,
          duration: 500,
        },
        left: newCoordinateStr,
        from: {
          right: rightStr,
          width: middleWidth,
          left: newCoordinateStr,
        },
        width: `${lineWidth}%`,
        right: `${100 - newCoordinate - lineWidth}%`,
      });
    },

    moveFromLeftToRight: async next => {
      const {
        newCoordinate,
      } = this.props;

      const lineWidth = 200 / window.innerWidth;
      const left = parseInt(this.nextLinePosition.left.slice(0, 2), 10);
      const right = 100 - left - lineWidth;
      const leftStr = `${left}%`;

      const newRight = `${100 - newCoordinate}%`;
      const middleWidth = `${newCoordinate - left}%`;

      await next({
        delay: 0,
        config: {
          easing,
          duration: 500,
        },
        left: leftStr,
        right: newRight,
        width: middleWidth,
        from: {
          right: `${right}%`,
          ...this.nextLinePosition,
        },
      });
      await next({
        delay: 500,
        right: newRight,
        config: {
          easing,
          duration: 500,
        },
        from: {
          left: leftStr,
          right: newRight,
          width: middleWidth,
        },
        width: `${lineWidth}%`,
        left: `${newCoordinate - lineWidth}%`,
      });
    },

    wiggle: async next => {
      // In this animation state newCoordinate should be 1 or -1 to indicate wiggle direction
      const {
        newCoordinate,
      } = this.props;

      const left = parseInt(this.nextLinePosition.left.slice(0, 2), 10);
      const leftStr = `${left}%`;

      const newLeft = `${left - newCoordinate * 10}%`;

      await next({
        delay: 0,
        left: newLeft,
        from: {
          left: leftStr,
        },
        config: {
          duration: 200,
        },
      });
      await next({
        delay: 200,
        left: leftStr,
        from: {
          left: newLeft,
        },
        config: {
          easing,
          duration: 400,
        },
      });
    },

    routeChangeFromMenu: async next => {
      await next({
        config: {
          easing,
          duration: 1000,
        },
        delay: 500,
        left: '50%',
        width: `${200 / window.innerWidth}%`,
        from: {
          left: '0%',
          width: '100%',
        },
      }, true);
    },

    routeChange: async next => {
      await next({
        delay: 0,
        config: {
          easing,
          duration: 1000,
        },
        left: '0%',
        width: '100%',
        from: this.nextLinePosition,
      });
      await next({
        delay: 1000,
        config: {
          easing,
          duration: 1000,
        },
        left: '50%',
        width: `${200 / window.innerWidth}%`,
        from: {
          left: '0%',
          width: '100%',
        },
      }, true);
    }
  });

  static getDerivedStateFromProps(nextProps, prevState) {
    if ((nextProps.state === 'collapse' || nextProps.state === 'routeChangeFromMenu') && !prevState.collapsed) {
      return ({
        collapseStarted: true,
      });
    }

    return ({
      collapseStarted: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.state !== this.props.state) {
      switch (this.props.state) {
        case 'expandFull': {
          this.lineCollapseOrigin = {
            ...this.nextLinePosition,
          };

          this.nextLinePosition = {
            left: '0%',
            width: '100%',
          };
          break;
        }
        case 'collapse': {
          this.nextLinePosition = {
            left: this.lineCollapseOrigin.left,
            width: `${200 / window.innerWidth}%`,
          };
          break;
        }
        case 'move':
        case 'moveFromLeftToRight':
        case 'moveFromRightToLeft': {
          this.nextLinePosition = {
            width: `${200 / window.innerWidth}%`,
            left: `${this.props.newCoordinate}%`,
          };
          break;
        }
        case 'routeChangeFromMenu':
        case 'routeChange': {
          this.nextLinePosition = {
            width: `${200 / window.innerWidth}%`,
            left: '50%',
          };
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  handleAnimationRest = () => {
    const {
      state,
    } = this.props;

    this.setState({
      expanded: this.props.state === 'expandFull',
    });

    if (state === 'collapse' || state === 'routeChangeFromMenu' || state === 'routeChange') {
      this.props.setBlockContent(false);
    }

    if (state === 'routeChange') {
      this.props.setHideHeader(false);
      this.props.setLineAnimation(null);
    }
  };

  handleAnimationFrames = ({ x }) => {
    if (this.props.state === 'loading' && Math.round(x) !== this.state.x) {
      this.setState({ x: Math.round(x) });
    }
  };

  handleAnimationStart = () => {
    const {
      state,
    } = this.props;

    if (
      state === 'loading'
      || state === 'expandFull'
      || state === 'routeChange'
      || state === 'routeChangeFromMenu'
    ) {
      this.props.setBlockContent(true);
    }

    if (state === 'routeChange') {
      this.props.setHideHeader(true);
    }
  };

  render() {
    const {
      state,
    } = this.props;

    const {
      LineComponent,
    } = this;

    return (
      <LineComponent
        native
        state={state}
        onRest={this.handleAnimationRest}
        onStart={this.handleAnimationStart}
        onFrame={this.handleAnimationFrames}
      >
        {props => {
          return (
            <animated.div
              className="main-line"
              style={{
                ...props
              }}
            >
              {state === 'loading' && (
                <div className="flex-box horizontal j-end a-center percent-wrapper">
                  <Text secondary className="percent">
                    {this.state.x}
                  </Text>
                </div>
              )}

              {this.state.expanded && (
                <Menu
                  currentPage={this.props.currentPage}
                  setCurrentPage={this.props.setCurrentPage}
                  isOpened={this.state.expanded && !this.state.collapseStarted}
                />
              )}
            </animated.div>
          );
        }}
      </LineComponent>
    );
  }
}
