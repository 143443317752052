import React from 'react';

import './index.scss';

const Input = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.value, props.name);
  };

  return (
    <input
      required
      name={props.name}
      type={props.type}
      autoComplete="off"
      value={props.value}
      onChange={handleChange}
      className={props.className}
      placeholder={props.placeholder}
    />
  );
};

Input.defaultProps = {
  name: '',
  value: '',
  type: 'text',
  placeholder: '',
  className: 'text',
  onChange: () => {},
};

export default Input;
