import React, { PureComponent } from 'react';

import {
  Text,
  ArrowText,
} from 'components';

import FooterBottom from './FooterBottom';

import {
  pages,
  // socials,
} from './constants';

import './index.scss';

export class FooterContent extends PureComponent {
  handleRouteChange = () => {
    this.props.setLineAnimation('routeChange');
  };

  render() {
    let nextPage = {};
    const currentPageIndex = pages.findIndex(page => page.label === this.props.currentPage);
    const nextPageIndex = currentPageIndex + 1;

    if (nextPageIndex !== pages.length) {
      nextPage = pages[ nextPageIndex ];
    } else {
      nextPage = pages[ 0 ];
    }

    return (
      <div className="flex-box no-wrap footer-content">
        <div className="flex-box no-wrap horizontal footer-body">
          <div
            className="flex-box no-grow footer-body-section"
          >
            <div className="flex-box no-grow next-wrapper">
              <Text
                main
                className="what-next"
              >
                {'What\'s next?'}
              </Text>

              <div className="flex-box no-grow no-wrap next-nav-section">

                <Text
                  secondary
                  className="next-text"
                >
                  Next
                </Text>

                <ArrowText
                  withDelay
                  to={nextPage.path}
                  data={nextPage.label}
                  text={nextPage.label}
                  onClick={this.handleRouteChange}
                  className="no-grow next-page-arrow"
                />
              </div>
            </div>
          </div>
          <div className="flex-box horizontal no-wrap navigation-wrapper">
            <div className="flex-box a-start no-wrap navigation">
              {pages.map((page, index) => (
                <ArrowText
                  withDelay
                  key={index}
                  to={page.path}
                  data={page.label}
                  text={page.label}
                  onClick={this.handleRouteChange}
                  className={`no-grow nav-item${currentPageIndex === index ? ' selected' : ''}`}
                />
              ))}
            </div>
            <div className="flex-box no-wrap socials">
              {/* {socials.map((social, index) => (
                <a
                  key={index}
                  target="_blank"
                  href={social.path}
                  className="social-item"
                  rel="noopener noreferrer"
                >
                  {social.label}
                </a>
              ))} */}
            </div>
          </div>
        </div>

        <FooterBottom/>
      </div>
    );
  }
}
