import React, { PureComponent } from 'react';
import {
  Spring,
} from 'react-spring/renderprops';

import {
  easing,
} from 'helpers';

export class MenuLine extends PureComponent {
  render() {
    const {
      opened,
      headerColor,
    } = this.props;

    return (
      <Spring
        delay={0}
        config={{
          easing,
          duration: 1000,
        }}
        to={{ borderColor: opened ? '#E7EAF3' : headerColor }}
        from={{ borderColor: opened ? headerColor : '#E7EAF3' }}
      >
        {props => (
          <div
            style={props}
            className="menu-line"
          />
        )}
      </Spring>
    );
  }
}
