import React, { PureComponent } from 'react';
import {
  withGesture,
} from 'react-with-gesture';
import {
  Spring,
  animated,
} from 'react-spring/renderprops';

import {
  Text,
  ArrowText,
} from 'components';

import {
  HRZ_SLIDER_DURATION,
  easing,
} from 'helpers';

import { AppContext } from 'contexts';

class Content extends PureComponent {
  static contextType = AppContext;

  componentDidUpdate(prevProps) {
    const {
      down,
      currentSlide,
      direction: [ x ],
      delta: [ deltaX, deltaY ],
    } = this.props;

    console.log(this.props);

    if (!down && prevProps.down && x && (deltaX > 10 || deltaX < -10) && Math.abs(deltaY) < Math.abs(deltaX)) {
      const direction = x > 0;

      if (
        (!direction && (currentSlide !== this.props.slides.length - 1))
        || (direction && !!currentSlide)
      ) {
        const addendum = direction ? -1 : 1;

        this.props.setCurrentSlide(currentSlide + addendum);
      }
    }
  }

  handleReadMoreClick = () => {
    this.context.setLineAnimation('routeChange');
  };

  render() {
    const {
      slides,
      currentSlide,
      previousSlide
    } = this.props;

    return (
      <div
        className="flex-box horizontal content-wrapper"
      >
        <Spring
          native
          delay={0}
          to={{ opacity: 1 }}
          from={{ opacity: 0 }}
          config={{
            easing,
            duration: HRZ_SLIDER_DURATION,
          }}
        >
          {props => (
            <animated.div
              style={props}
              className={`flex-box horizontal no-wrap content fade-in-${currentSlide}`}
            >
              {slides[ currentSlide ].path && (
                <ArrowText
                  withDelay
                  text="Read more"
                  to={slides[ currentSlide ].path}
                  onClick={this.handleReadMoreClick}
                  className="no-grow a-end no-wrap slider-read-more"
                />
              )}

              <Text
                secondary
                className="content-text"
              >
                {this.props.currentSlideText}
              </Text>
            </animated.div>
          )}
        </Spring>

        <Spring
          native
          delay={0}
          to={{ opacity: 0 }}
          from={{ opacity: 1 }}
          config={{
            easing,
            duration: HRZ_SLIDER_DURATION,
          }}
        >
          {props => (
            <animated.div
              style={props}
              className={`flex-box horizontal no-wrap content fade-out-${previousSlide}`}
            >
              {slides[ previousSlide ].path && (
                <ArrowText
                  withDelay
                  text="Read more"
                  to={slides[ previousSlide ].path}
                  onClick={this.handleReadMoreClick}
                  className="no-grow a-end no-wrap slider-read-more"
                />
              )}

              <Text
                secondary
                className="content-text"
              >
                {this.props.previousSlideText}
              </Text>
            </animated.div>
          )}
        </Spring>
      </div>
    );
  }
}

export default withGesture()(Content);
