// @flow
import React, { PureComponent } from 'react';

import {
  Text,
} from 'components';

import './index.scss';

export default class Indicators extends PureComponent {
  handleIndicatorClick = (index) => () => {
    this.props.onIndicatorClick(index);
  };

  render() {
    const {
      sliderName,
    } = this.props;

    return (
      <div className="flex-box horizontal indicators">
        {sliderName && (
          <Text
            secondary
            className="slider-name"
          >
            {sliderName}
          </Text>
        )}

        <div className="flex-box horizontal no-grow no-shrink no-wrap indicator-numbers">
          {this.props.slides.map((slide, index) => (
            <div
              key={index}
              className={`flex-box no-grow no-shrink no-wrap indicator-number-wrapper indicator-number-wrapper-${index}`}
            >
              <Text
                secondary
                onClick={this.handleIndicatorClick(index)}
                className={`indicator indicator-${index}${this.props.currentSlide === index ? ' selected' : ''}`}
              >
                {`0${index + 1} /`}
              </Text>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
