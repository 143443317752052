import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { Text } from 'components';

import './index.scss';

class ArrowTextComp extends PureComponent {
  static defaultProps = {
    to: '/',
    onClick: () => {
    },
  };

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleClick = (e) => {
    this.props.onClick(this.props.data, e);

    if (this.props.withDelay) {
      const nextPath = this.props.to;

      this.timeout = setTimeout(() => {
        this.props.history.push(nextPath);
      }, 1000);
    } else {
      this.props.history.push(this.props.to);
    }
  };

  render() {
    const {
      className,
    } = this.props;

    return (
      <div
        className={`flex-box horizontal j-start a-center arrow-text${className ? ` ${className}` : ''}`}
      >
        <Text
          onClick={this.handleClick}
        >
          {this.props.text}
        </Text>
        <span
          className="arrow"
          onClick={this.handleClick}
        >
          &#8594;
        </span>
      </div>
    );
  }
}

export const ArrowText = withRouter(ArrowTextComp);
