import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Loading from 'react-loading-bar';

import { AppContext } from 'contexts';

import 'react-loading-bar/dist/index.css';
import Slide from './Slide';

class PageSliderComp extends PureComponent {
  static contextType = AppContext;

  state = {
    blockSlideChangeBottom: true,
    blockSlideChangeTop: true,
    blockSlideChange: true,
    previousSlide: 0,
    direction: true,
    currentSlide: 0,
    nextSlide: 0,
    fast: false,
  };

  componentDidUpdate() {
    const {
      lineState,
    } = this.context;

    if (
      lineState === 'wiggle'
      || lineState === 'move'
    ) {
      this.context.setLineAnimation(null);
    }
  }

  componentDidMount() {
    const {
      pageName,
    } = this.props;

    this.timout = setTimeout(
      this.setInitialMountingAnimations,
      this.context.lineState === 'loading' ? 4500 : 1000
    );

    this.context.setHeaderLogoClick(pageName === 'Home' ? this.setDefaultState : this.handleHeaderLogoClick);
    this.context.setHeaderColor(this.props.slides[ 0 ].headerColor || '#1C1E29');
    this.context.setOnSlideClick(() => this.handleSlideChange(1));
    this.context.setFooterAnimationCallback(this.unblockSlideChange);
    this.context.setOnFooterTouchStart(this.handleTouchStart);
    this.context.setOnFooterTouchMove(this.handleTouchEnd);
    this.context.setOnFooterScroll(this.handleScroll);
    this.context.setCurrentPage(pageName);
  }

  componentWillUnmount() {
    clearTimeout(this.timout);
    clearTimeout(this.routeChangeTimeout);
  }

  setDefaultState = () => {
    if (this.state.currentSlide !== 0) {
      this.setState(
        {
          blockSlideChange: true,
          previousSlide: 0,
          direction: false,
          currentSlide: 0,
          nextSlide: 1,
        },
        () => {
          this.context.setHeaderColor('#1C1E29');
          this.context.setLineAnimation('move', 50);
          this.context.setFooterAnimationState(this.getSlideAnimations(this.props.slides.length));
        }
      );
    }
  };

  handleHeaderLogoClick = () => {
    this.routeChangeTimeout = setTimeout(() => this.props.history.push('/'), 1000);
    this.context.setLineAnimation('routeChange');
  };

  setInitialMountingAnimations = () => {
    this.setState(
      {
        nextSlide: 1,
      },
      () => {
        if (this.context.lineState === 'loading') {
          this.context.setLineAnimation('collapse');
        }
      }
    );
  };

  handleSlideChange = (direction) => {
    const {
      currentSlide,
    } = this.state;

    const length = this.props.slides.length;

    const bottomDirection = direction > 0;

    const newCurrentSlide = currentSlide + direction;

    if (
      (this.state.blockSlideChange && (bottomDirection ? this.state.blockSlideChangeBottom : this.state.blockSlideChangeTop))
      || (newCurrentSlide > length && bottomDirection)
      || (newCurrentSlide < 0 && !bottomDirection)
    ) {
      return null;
    }

    const { coordinate } = this.props.lineMovementProperties[ newCurrentSlide ];
    const previousSlide = newCurrentSlide - 1;

    let newState = {
      direction: bottomDirection,
      currentSlide: newCurrentSlide,
      nextSlide: newCurrentSlide + 1,
      previousSlide: previousSlide >= 0 ? previousSlide : 0,
    };

    if (this.props.slides[ newCurrentSlide ] && this.props.slides[ newCurrentSlide ].scrollable) {
      newState = {
        direction: bottomDirection,
        currentSlide: newCurrentSlide,
        previousSlide: previousSlide >= 0 ? previousSlide : 0,
        nextSlide: !bottomDirection ? (newCurrentSlide + 1) : null,
      };
    }

    if (this.props.manualSlideChange) {
      newState = this.props.manualSlideChange(direction, currentSlide);
    }

    this.setState(
      {
        ...newState,
        fast: false,
        blockSlideChange: true,
        blockSlideChangeTop: true,
        blockSlideChangeBottom: true,
      },
      () => {
        this.context.setLineAnimation('move', coordinate);
        this.context.setFooterAnimationState(this.getSlideAnimations(length));
        this.context.setHeaderColor((this.props.slides[ this.state.currentSlide ] || {}).headerColor || '#1C1E29');
      }
    );
  };

  handleScroll = (event) => {
    event.persist();

    if (!this.context.blockContent) {
      this.handleSlideChange(event.deltaY > 0 ? 1 : -1);
    }
  };

  handleTouchStart = (e) => {
    this.initialY = e.touches[ 0 ].clientY;
    this.initialX = e.touches[ 0 ].clientX;
  };

  handleTouchEnd = (e) => {
    if (this.initialY === null) {
      return false;
    }

    const currentY = e.changedTouches[ 0 ].clientY;
    const currentX = e.changedTouches[ 0 ].clientX;

    const diffY = this.initialY - currentY;
    const diffX = this.initialX - currentX;

    if (!diffY || (Math.abs(diffX) > Math.abs(diffY))) {
      return false;
    }

    const direction = diffY > 0 ? 1 : -1;

    this.handleSlideChange(direction);

    this.initialY = null;
    this.initialX = null;
  };

  getSlideAnimations = (slideNumber) => {
    const {
      direction,
      nextSlide,
      currentSlide,
      previousSlide,
    } = this.state;

    if (slideNumber === 0) {
      return ({
        isPrevious: previousSlide === slideNumber,
        isCurrent: currentSlide === slideNumber,
        isNext: false,
        top100: false,
        top95: false,
        top0: false,
      });
    }

    return ({
      direction,
      top100: slideNumber > nextSlide,
      top95: slideNumber === nextSlide,
      isNext: slideNumber === nextSlide,
      top0: slideNumber <= currentSlide,
      isCurrent: currentSlide === slideNumber,
      isPrevious: previousSlide === slideNumber,
      hideSlow: typeof this.props.hideSlow === 'function' && this.props.hideSlow(slideNumber, this.state),
      blockAnimationCallback: nextSlide === slideNumber && (this.props.slides[ slideNumber - 1 ] || {}).scrollable,
      forceAnimationCallback: (
        currentSlide === this.props.slides.length
        || (typeof this.props.forceCallbackCondition === 'function' && this.props.forceCallbackCondition(slideNumber, this.state))
      ),
    });
  };

  blockSlideChange = () => {
    this.setState({
      blockSlideChange: true,
      blockSlideChangeTop: true,
      blockSlideChangeBottom: true,
    });
  };

  unblockSlideChange = (cb) => {
    this.setState(
      {
        blockSlideChange: false,
        blockSlideChangeTop: false,
        blockSlideChangeBottom: false,
      },
      () => {
        cb && cb();
      }
    );
  };

  unblockDirectionSlideChange = (direction, cb) => {
    this.setState(
      {
        [ `blockSlideChange${direction}` ]: false,
      },
      () => {
        cb && cb();
      }
    );
  };

  blockDirectionSlideChange = (direction, cb) => {
    this.setState(
      {
        [ `blockSlideChange${direction}` ]: true,
      },
      () => {
        cb && cb();
      }
    );
  };

  handleSlideIndicatorClick = (slideNumber) => () => {
    this.handleSlideChange(slideNumber - 1);
  };

  onIndicatorMouseEnter = (slideNumber) => () => {
    if (!this.state.blockSlideChange && this.state.currentSlide === 1) {
      this.setState({ nextSlide: slideNumber, fast: true, });
    }
  };

  onIndicatorMouseLeave = () => {
    if (!this.state.blockSlideChange && this.state.currentSlide === 1) {
      this.setState({ nextSlide: null, fast: false });
    }
  };

  setNextSlide = (nextSlide, executeCB, cb) => {
    if (this.state.nextSlide !== nextSlide) {
      this.setState(
        {
          nextSlide,
        },
        () => {
          if (executeCB) {
            cb && cb();
            this.context.setFooterAnimationState(this.getSlideAnimations(this.props.slides.length));
          }
        }
      );
    }
  };

  actions = {
    onIndicatorMouseLeave: this.onIndicatorMouseLeave,
    onIndicatorMouseEnter: this.onIndicatorMouseEnter,
    handleSlideIndicatorClick: this.handleSlideIndicatorClick,
  };

  render() {
    const {
      slides,
      className,
    } = this.props;

    return (
      <div
        onWheel={this.handleScroll}
        onTouchEnd={this.handleTouchEnd}
        className={`flex-box ${className}`}
        onTouchStart={this.handleTouchStart}
      >
        <Loading
          color="#002AFF"
          showSpinner={false}
          key={this.state.currentSlide}
          show={!(slides[this.state.currentSlide] || {}).scrollable && this.context.lineState !== 'loading' && this.state.blockSlideChange}
        />
        {slides.map(slide => (
          <Slide
            key={slide.id}
            fast={this.state.fast}
            slideNumber={slide.id}
            scrollable={slide.scrollable}
            initialTop={slide.initialTop}
            fadeInDelay={slide.fadeInDelay}
            nextSlide={this.state.nextSlide}
            setNextSlide={this.setNextSlide}
            initialState={slide.initialState}
            withParallax={slide.withParallax}
            onSlideClick={this.handleSlideChange}
            {...this.getSlideAnimations(slide.id)}
            blockSlideChange={this.blockSlideChange}
            contentClassName={slide.contentClassName}
            unblockSlideChange={this.unblockSlideChange}
            blockDirectionSlideChange={this.blockDirectionSlideChange}
            unblockDirectionSlideChange={this.unblockDirectionSlideChange}
            className={`${slide.className || ''} ${className}-slide-${slide.id + 1}${this.state.fast ? ' fast' : ''}`}
          >
            {typeof slide.children === 'function' ? slide.children(this.actions, this.state) : slide.children}
          </Slide>
        ))}
      </div>
    );
  }
}

export const PageSlider = withRouter(PageSliderComp);
