import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import Home from 'pages/Home';
import Career from './Career';
import Service from './Service';
import About from 'pages/About';
import Contacts from 'pages/Contacts';
import Practices from 'pages/Practices';
import Expertise from 'pages/Expertise';

export const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home}/>
    <Route exact path="/about" component={About}/>
    <Route exact path="/contacts" component={Contacts}/>
    <Route exact path="/practices" component={Practices}/>
    <Route exact path="/expertise" component={Expertise}/>
    <Route exact path="/career" component={Career}/>
    <Route exact path="/services" component={Service}/>
    <Route component={Home}/>
  </Switch>
);
