import BezierEasing from 'bezier-easing';

// initial easing settings [ 0.8, 0, 0.2, 1 ];
const settingsEaseIn = [ 0.25, 0.1, 0.25, 1 ];

export const getClassNames = (classNamesObject) => {
  const keys = Object.keys(classNamesObject);

  return keys.reduce(
    (accumulator, currentValue) => `${accumulator}${classNamesObject[currentValue] ? ` ${currentValue}` : ''}`,
    '',
  );
};

export const HRZ_SLIDER_DURATION = 700;

export const easing = (value) => BezierEasing(...settingsEaseIn)(value);

export const preventer = (event) => {
  event.preventDefault();
  event.persist();
};
